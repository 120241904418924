import * as React from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import '../App.css';

import logo from "../assets/logo.png";

export default function NavBar() {
    const LogoStyle={
        height:'50px',
        mx: "auto",
        my: "10px",
    };
    return (
        <AppBar
            position="relative"
            >
            <Toolbar className = "NavBar">
                <img 
                src = {logo}
                style = {LogoStyle}/>
                <Typography variant="h3" component="div" sx={{ flexGrow: 1 }}>
                    ReactTrack
                </Typography>
            </Toolbar>
        </AppBar>
    );
}
