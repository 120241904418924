import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MailchimpSubscribe, { EmailFormFields } from "react-mailchimp-subscribe";



const MailchimpFormContainer = (props:any) =>{

    const postUrl:string = `https://reacttrack.us21.list-manage.com/subscribe/post?u=2d2b326f26016dbd52f80f823&id=1ac0b752a7`
    return (
        <div className = "mc__form-container">
            <MailchimpSubscribe
                url = {postUrl}
                render={({subscribe, status, message }) => (
                    <CustomForm
                        status = {status}
                        message = {message}
                        onValidated = {(formData: EmailFormFields) => subscribe(formData)}
                    />
                )}
            />
        </div>
    );
};

function CustomForm({ status, message, onValidated }:any) {

    // function handleChange(e:any){
    //     var email = e.target.value;

    //     setEmail(e.target.value);
    //     if(validator.isEmail(email)){
    //         setMessage("");
    //     }else {
    //         setMessage("Please enter a valid email");
    //     }
    // }
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    
    const handleSubmit = (e:any) => {
        e.preventDefault();
        email &&
        email.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email,
            MERGE1: firstName,
            MERGE2: lastName,
        });
    }

    return (
        <>
        <Box
            sx={{
                height: 500,
                backgroundColor: "secondary"
            }}>
            <Typography  variant="h5" component="div" color="secondary" sx={{ flexGrow: 1 }}>
            
            {status === "success" 
                ? "Success!" 
                : "Join our email list for future updates!"
            }

            </Typography>
            <form 
                onSubmit= {e => {handleSubmit(e)}}
                >
                    
                    <div>
                        <TextField
                        required
                        id="outlined-required"
                        label="Email (Required)"
                        value={email}
                        onChange={e=> setEmail(e.target.value)}
                        margin="normal"
                        />

                        <br/>

                        <TextField
                        label="First Name (Optional)"
                        value = {firstName}
                        onChange = {e => setFirstName(e.target.value)}
                        margin="normal"
                        />
                        
                        <br/>

                        <TextField
                        label = "Last Name (Optional)"
                        value = {lastName}
                        onChange={e => setLastName(e.target.value)}
                        margin="normal"
                        />
                    {status === "sending" && (
                    <div className="mc__alert mc__alert--sending">
                        sending...
                    </div>
                    )}
                    {status === "error" && (
                    <div 
                        className="mc__alert mc__alert--error"
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                    )}
                    {status === "success" && (
                    <div
                        className="mc__alert mc__alert--success"
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                    )}
                    </div>

                    <Button variant="contained" color="secondary" type="submit">Submit</Button>
                
                </form> 
        </Box>   
                   
        </>
    );
}

export default MailchimpFormContainer;