import React from 'react';
import './App.css';
import HomePage from './components/HomePage';

function App() {
  return (
    <div className="App">
      <HomePage

      />
    </div >
  );
}

export default App;
