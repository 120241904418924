import * as React from "react";
import Box from "@mui/material/Box";
import sloganImage from "../assets/slogan-image.png";



//add train tracks logo
export default function Slogan() {

    const imageStyle:any={
        mx: "auto",
        my: "10px",
        aspectRatio: "2940:1243",
        maxWidth: "100%",
        height: "auto",
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    };
    return (

        <Box
            sx={{
                height: "auto",
                mx: "auto",
                my: "auto"
            }}>


            <img
                src={sloganImage}
                style={imageStyle}
                alt="Reactions lead to solutions"
            />
            <br/>
            <br/>
            <br/>

        </Box>
    );
}