import * as React from "react";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Badge from "@mui/material/Badge"
import Card from "@mui/material/Card";

import MKBox from "../components/MKBox";
import MKBadge from "../components/MKBadge";
import MKTypography from "../components/MKTypography";
import { readBuilderProgram } from "typescript";

import "../assets/style.css";

import calendarImage from "../assets/calendar.png";
import quickCheckinImage from "../assets/quickcheckin.png";
import notesImage from "../assets/notes.png";
import whatHappenedImage from "../assets/whathappened.png";
import quickTopicsImage from "../assets/quicktopics.png";

export default function Demo() {
    const calendarImageStyle={
        aspectRatio:'581:2508',
        // height:'500px',
        maxWidth:'28vw',
        maxHeight:'60vh',
        mx: "auto",
        my: "10px",
    };

    const textStyle = {
        color: "white",
    };
    
    return (
        <Box component="section" my={6} py={6} style = {textStyle}>
            <Container>
                <Grid
                container
                item
                xs={12}
                lg={6}
                flexDirection="column"
                alignItems="center"
                sx={{ textAlign: "center", my: 0, mx: "auto", px: 0.75}} 
                >
                    <Typography variant="h3" fontWeight="bold">
                        Why ReactTrack?
                    </Typography>
                </Grid>
            </Container>
            <Container sx={{ textAlign: "center", mt: 6 }}>
                <Grid container spacing={6} sx={{ mb: 10 }}>
                    <Grid item xs={8} lg={8}>
                        <Box position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
                            <Typography variant="h4" fontWeight="bold" mb={1}>
                                See Your Emotions at a Glance
                            </Typography>
                            <Typography variant="body2" fontWeight="regular" mb={1} pr={2}>
                                <h2>The ReactTrack calendar shows you a color coordinated interface to show you how you were feeling each day.</h2>
                            </Typography>
                            <Typography variant="body2" fontWeight="regular" mb={1} pr={2}>
                                <h2>You can see mood trends.</h2>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid className="desktop" item xs={4} lg={4}>
                        <Grid container spacing={3}>
                            <img 
                            src = {calendarImage}
                            style = {calendarImageStyle}
                            alt = "Calendar" />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>

            <Box component="section" my={6} mx={3} py={6}>
                <Container sx={{ textAlign: "center", mt: 10 }}>
                    <Grid container spacing={6} sx={{ mb: 10 }}>
                        <Grid item className="desktop" xs={4} lg={4}>
                            <Grid container spacing={3}>
                                <img 
                                src = {quickCheckinImage}
                                style = {calendarImageStyle}
                                alt = "Calendar" />
                            </Grid>
                            <br />
                            <br />
                            <Grid container spacing={3}>
                                <img 
                                src = {quickTopicsImage}
                                style = {calendarImageStyle}
                                alt = "Calendar" />
                            </Grid>
                        </Grid>
                        <Grid item xs={8} lg={8}>
                            <Box position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
                                <Typography variant="h4" fontWeight="bold" mb={1}>
                                    Don't have time to record all of your feelings?
                                </Typography>
                                <Typography variant="h4" fontWeight="bold" mb={1}>
                                    No Problem!
                                </Typography>
                                <Typography variant="body2" fontWeight="regular" mb={1} pr={2}>
                                    <h2>Quickly record your emotions with general emotions to keep your calendar updated.</h2>
                                </Typography>
                                <Typography variant="body2" fontWeight="regular" mb={1} pr={2}>
                                    <h2>Or use quick topics to find easily customizable emotion templates to make detailed records on the move.</h2>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <Box component="section" my={0} py={0}>
                <Container sx={{ textAlign: "center", mt: 10 }}>
                    <Grid container spacing={6} sx={{ mb: 10 }}>
                        <Grid item xs={8} lg={8}>
                            <Box position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
                                <Typography variant="h4" fontWeight="bold" mb={1}>
                                    Fully Customizable Detailed Emotion Tracking
                                </Typography>
                                <Typography variant="body2" fontWeight="regular" mb={1} pr={2}>
                                    <h2>Easily choose how you would like to document your emotions with lots of options.</h2>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item className="desktop" xs={4} lg={4}>
                            <Grid container spacing={3}>
                                <img 
                                src = {whatHappenedImage}
                                style = {calendarImageStyle}
                                alt = "Calendar" />
                            </Grid>
                            <br />
                            <br />
                            <Grid container spacing={3}>
                                <img 
                                src = {notesImage}
                                style = {calendarImageStyle}
                                alt = "Calendar" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}

